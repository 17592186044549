import React from 'react';
import { styled } from 'linaria/react';
import Image from '@jetshop/ui/Image/Image';
import { theme } from '../../../Theming/Theming';
import { useContext } from 'react';
import SiteContentContext from '../../../SiteContent/SiteContentContext';
import { Link } from 'react-router-dom';

const Wrapper = styled('div')`
  position: relative;
  .content-html {
    position: absolute;
    top: 0;
    right: 0;
    padding: 1rem;
  }
  [data-flight-image-container] {
    padding-bottom: 90% !important;
    ${theme.above.xl} {
      padding-bottom: 75% !important;
    }
    ${theme.above.xxl} {
      padding-bottom: 50% !important;
    }
  }
`;

const CampaignImage = () => {
  const {
    desktopMenuCampaignLink,
    desktopMenuCampaignImage,
    desktopMenuCampaignHtml,
  } = useContext(SiteContentContext);
  return (
    <Wrapper className="shop-by-area-campaign">
      <Link to={desktopMenuCampaignLink}>
        <div className="image-holder">
          <Image
            src={desktopMenuCampaignImage}
            alt={desktopMenuCampaignHtml}
            sizes={[1 / 3]}
            critical={true}
            aspect={'1:1'}
            crop={true}
            cover
          />
        </div>
        {desktopMenuCampaignHtml && (
          <div className="content-html">{desktopMenuCampaignHtml}</div>
        )}
      </Link>
    </Wrapper>
  );
};

export default CampaignImage;
