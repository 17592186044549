import { useQuery } from 'react-apollo';
import { filterList, getId } from './helpers';
import siblingSearchQuery from './siblingSearchQuery.gql';

export const useSiblingProducts = ({ product }) => {
  //GET SEARCH TERM
  const term = getId(product);

  //QUERY
  const result = useQuery(siblingSearchQuery, {
    variables: { term: term },
    errorPolicy: 'all',
    skip: !term,
  });

  const productList = result.data?.search?.products?.result;

  if (productList?.length > 1) return filterList(productList, term);
  return null;
};
