import React, { useContext } from 'react';
import { styled } from 'linaria/react';
import { VariantHeading } from '../VariantSelector/VariantButtons';
import ProductLink from '@jetshop/ui/ProductLink';
import { theme } from '../../Theming/Theming';
import SiteContentContext from '../../SiteContent/SiteContentContext';
import { filterListOnField } from './helpers';
import Image from '@jetshop/ui/Image';
import { useState } from 'react';
import { imageOverlay } from '../../ui/General';

const ButtonWrapper = styled('div')`
  > ul {
    display: flex;
    margin: 0 -5px;
    flex-wrap: wrap;
    > li {
      text-align: center;
      width: auto;
      margin: 0 5px 5px;
      border: 2px solid ${theme.colors.secondary};
      overflow: hidden;
      > * {
        display: block;
      }
      &.image {
        width: 50px;
        > * {
          ${imageOverlay}
        }
      }
      .color {
        display: block;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        position: relative;
        &.no-color-code {
          &:after {
            content: '';
            transform: rotate(45deg);
            position: absolute;
            height: 2px;
            top: calc(50% - 1px);
            width: 100%;
            background: #999;
            left: 0;
          }
        }
      }
    }
  }
  &:not(.grid) {
    margin: 1rem 0;
    > ul {
      > li {
        position: relative;
        &.image {
          overflow: visible;
          width: 80px;
          margin: 0 5px 5px 0;
          padding: 0;
          border: none;
          &.selected {
            &:after {
              content: '';
              width: 100%;
              height: 4px;
              background: ${theme.colors.font};
              position: absolute;
              left: 0;
              bottom: -10px;
            }
          }
        }
      }
    }
  }
  &.grid {
    overflow: hidden;
    position: relative;
    &:after {
      position: absolute;
      right: 0;
      width: 50px;
      top: 0;
      content: '';
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 80%,
        rgba(255, 255, 255, 1) 100%
      );
      height: 100%;
    }
    > ul {
      margin: 0 -2px;
      > li {
        margin: 0 2px 2px;
        &.image {
          border: 1px solid #fff;
          width: 40px;
          > * {
            padding: 0;
          }
          &.selected {
            border-color: #fff;
          }
        }
      }
    }
  }
`;

export const SiblingButtons = ({
  productList,
  mainProduct,
  grid = false,
  setImage = false,
  setProduct = false,
}) => {
  const { colorsTitle } = useContext(SiteContentContext);
  const [active, setActive] = useState(false);
  const list = filterListOnField(
    productList,
    mainProduct,
    'size',
    'atr_colour'
  );

  if (list.length === 0) return null;

  return (
    <ButtonWrapper className={`sibling-button-wrapper ${grid ? 'grid' : ''}`}>
      {!grid && <VariantHeading>{colorsTitle}</VariantHeading>}
      <ul>
        {list.map((product, index) => {
          const currentProduct = product.id === mainProduct.id;
          const Tag = currentProduct ? 'div' : ProductLink;
          const image =
            product.images && product.images.length > 0 && product.images[0];
          return (
            <li
              key={product.id}
              className={`image ${
                product.stockStatus?.buyable ? 'buyable' : 'not-buyable'
              } ${currentProduct ? 'selected' : ''} ${
                active === index ? 'hovered' : ''
              }`}
              onMouseEnter={() => {
                if (setImage && product?.images.length > 0) {
                  setImage(product.images[0]);
                  setActive(index);
                }
              }}
              onClick={() => {}}
            >
              <Tag
                product={product}
                onClick={(e) => {
                  if (setProduct) {
                    setProduct(product);
                    e.preventDefault();
                  }
                }}
              >
                {image && (
                  <Image
                    sizes={grid ? [50] : [100]}
                    aspect={theme.productGrid.product.imageAspect}
                    crop={false}
                    src={image.url}
                    alt={image.alt}
                    modifiedDate={image.modifiedDate}
                  />
                )}
              </Tag>
            </li>
          );
        })}
      </ul>
    </ButtonWrapper>
  );
};
