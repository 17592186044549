import React, { useContext } from 'react';
import { styled } from 'linaria/react';
import { VariantHeading } from '../VariantSelector/VariantButtons';
import ProductLink from '@jetshop/ui/ProductLink';
import { theme } from '../../Theming/Theming';
import SiteContentContext from '../../SiteContent/SiteContentContext';
import { filterListOnField, getField } from './helpers';

const ButtonWrapper = styled('div')`
  > ul {
    display: flex;
    margin: 0 -5px;
    flex-wrap: wrap;
    > li {
      background: transparent;
      border: 1px solid #777;
      border-radius: 2px;
      cursor: pointer;
      text-align: center;
      margin: 0 5px 5px 0;
      font-size: 0.9rem;
      width: auto;
      position: relative;
      > * {
        display: block;
        padding: 0.2rem 0.8rem;
      }
      &.selected {
        &:after {
          content: '';
          width: 100%;
          height: 4px;
          background: ${theme.colors.font};
          position: absolute;
          left: 0;
          bottom: -10px;
        }
      }
      &.not-buyable {
        background: #eee;
        border-color: #ddd;
        > * {
          color: #999;
          text-decoration: line-through;
        }
      }
    }
  }
`;

export const SiblingSizeButtons = ({ productList, mainProduct }) => {
  const { sizeTitle } = useContext(SiteContentContext);
  const list = filterListOnField(
    productList,
    mainProduct,
    'atr_colour',
    'size'
  );
  if (list.length === 0) return null;
  return (
    <ButtonWrapper className={`sibling-button-wrapper`}>
      <VariantHeading>{sizeTitle}</VariantHeading>
      <ul>
        {list.map((product) => {
          const currentProduct = product.id === mainProduct.id;
          const Tag = currentProduct ? 'div' : ProductLink;
          const name = getField(product, 'size');
          return (
            <li
              key={product.id}
              className={`${currentProduct ? 'selected' : ''} ${
                product.stockStatus?.buyable ? 'buyable' : 'not-buyable'
              }`}
            >
              <Tag product={product}>{name}</Tag>
            </li>
          );
        })}
      </ul>
    </ButtonWrapper>
  );
};
