import ChannelContext from '@jetshop/core/components/ChannelContext';
import React, { useContext, useEffect } from 'react';
import { styled } from 'linaria/react';
import { config } from '../../shop.config.custom';

const KlarnaPaymentWrapper = styled('div')`
  width: 100%;
  margin-top: 1rem;
`;

const KlarnaFAQWrapper = styled('div')`
  width: 100%;
  margin-top: 1rem;
`;

export const KlarnaScript = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.klarna.com/web-sdk/v1/klarna.js';
    script.setAttribute('data-client-id', config.settings.klarna);
    script.setAttribute('data-environment', 'production');
    script.async = true;
    script.setAttribute('id', 'klarna-script');
    document.body.appendChild(script);
    return () => {
      if (typeof Klarna !== 'undefined') {
        window.Klarna.OnsiteMessaging.loaded = false;
      }
      document.body.removeChild(script);
    };
  });
  return null;
};

export const KlarnaPayment = ({ price }) => {
  const { selectedChannel } = useContext(ChannelContext);
  return (
    <KlarnaPaymentWrapper>
      <klarna-placement
        data-locale={selectedChannel.language.culture}
        data-key="credit-promotion-auto-size"
        data-purchase-amount={price * 100}
      />
    </KlarnaPaymentWrapper>
  );
};

export const KlarnaFAQ = ({ price }) => {
  const { selectedChannel } = useContext(ChannelContext);
  return (
    <KlarnaFAQWrapper>
      <klarna-placement
        data-key="info-page"
        data-locale={selectedChannel.language.culture}
      ></klarna-placement>
    </KlarnaFAQWrapper>
  );
};
