import React, { useContext } from 'react';
import { styled } from 'linaria/react';
import { Link } from 'react-router-dom';
import Image from '@jetshop/ui/Image/Image';
import { theme } from '../../../Theming/Theming';
import SiteContentContext from '../../../SiteContent/SiteContentContext';

const ShopByAreaWrapper = styled('div')`
  padding: 2rem 3rem;
  background: ${theme.colors.greys[0]};
  h4 {
    font-weight: ${theme.weights.bold};
    margin-bottom: 1rem;
  }
  > ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    > li {
      width: 25%;
      padding: 10px;
      text-align: center;
      .image-holder {
        border-radius: 50%;
        overflow: hidden;
      }
      h5 {
        margin-top: 5px;
      }
    }
  }
  &.mobile {
    padding: 1rem 2rem;
    > ul {
      margin: 0 -5px;
      > li {
        padding: 5px;
      }
    }
  }
`;

const TitleWrapper = styled('div')``;

const ShopByArea = ({ className }) => {
  const { menuImages } = useContext(SiteContentContext);
  const titleText = menuImages.titleText?.value;
  const titleLink = menuImages.titleLink?.value;
  const Tag = titleLink ? Link : TitleWrapper;
  return (
    <ShopByAreaWrapper className={`shop-by-area-categories ${className}`}>
      <Tag to={titleLink}>
        <h4>{titleText}</h4>
      </Tag>
      <ul>
        {menuImages?.items?.map((item, index) => {
          const link = item.link?.value;
          const image = item.imageUrl?.value;
          const name = item.name?.value;
          return (
            <li key={index}>
              <Link to={link}>
                <div className="image-holder">
                  <Image
                    src={image}
                    alt={name}
                    sizes={[200]}
                    critical={true}
                    aspect={'1:1'}
                    crop
                    cover
                  />
                </div>
                <h5>{name}</h5>
              </Link>
            </li>
          );
        })}
      </ul>
    </ShopByAreaWrapper>
  );
};

export default ShopByArea;
