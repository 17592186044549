import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theming/Theming';
import { convertPropsToObject } from '../ContentRender';
import { Link } from 'react-router-dom';
import { RowHeader } from './RowHeader';
import Image from '@jetshop/ui/Image/Image';
import { ScrollBox } from '../../ui/ScrollBox';

const CirclesWrapper = styled.div`
  margin: 6% 0;
  ${theme.above.lg} {
    margin: 5% 0;
    .row-header {
      text-align: center;
    }
    .circle-list {
    }
  }
  .circle-list {
    .no-scroll {
      .slider-items {
        justify-content: center;
      }
    }
    .circle {
      text-align: center;
      margin-right: 2%;
      ${theme.only.sm} {
        width: 28%;
      }
      ${theme.only.md} {
        width: 17%;
      }
      ${theme.only.lg} {
        width: 14%;
      }
      ${theme.above.xl} {
        width: 10.62%;
      }
      .image-holder {
        border-radius: 50%;
        overflow: hidden;
      }
      h3 {
        margin-top: 1rem;
        font-weight: ${theme.weights.bold};
      }
    }
  }
`;

const CircleComponent = ({ data }) => {
  const props = convertPropsToObject(data);
  const name = props.name?.value;
  const link = props.link?.value;
  const image = props.imageUrl?.value;
  if (!name || !link || !image) return null;
  return (
    <Link to={link} className="circle">
      <Image
        className="image-holder"
        aspect={'1:1'}
        sizes={[200]}
        src={image}
        alt={name}
        crop={true}
        cover={true}
      />
      <h3>{name}</h3>
    </Link>
  );
};

const Circles = ({ data, index }) => {
  const props = convertPropsToObject(data);
  const columns = data.children.length;

  if (data && data.children)
    return (
      <CirclesWrapper data-index={index}>
        <RowHeader props={props} />
        <div className="circle-list" data-columns={columns}>
          <ScrollBox>
            {data.children.map((child, index) => (
              <CircleComponent data={child} key={index} />
            ))}
          </ScrollBox>
        </div>
      </CirclesWrapper>
    );
  else return null;
};

export default Circles;
