import React, { useState, useEffect, useRef } from 'react';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import CategoryLink from '@jetshop/ui/CategoryLink';
import CloseButton from '../../../ui/CloseButton';
import t from '@jetshop/intl';
import { theme } from '../../../Theming/Theming';
import { headerTransitions } from './DesktopHeader';
import { config } from '../../../../shop.config.custom';
import ShopByArea from './ShopByArea';
import CampaignImage from './CampaignImage';

const boxShadow = `
  box-shadow: 0px 3px 4px #00000012;
`;

const SubMenuWrapper = styled('div')`
  display: none;
  max-height: calc(100vh - ${theme.header.height.lg + 'px'});
  overflow: auto;
  overflow-x: hidden;
  background: white;
  z-index: 2;
  border-top: 1px solid #eee;
  ${headerTransitions}
  &.mega-menu {
    position: fixed;
    width: 100%;
    left: 0;
    top: ${theme.desktopHeader.height.lg + theme.topBar.height.lg + 'px'};
    ${boxShadow}
    ${theme.only.md} {
      top: ${theme.desktopHeader.height.md + +theme.topBar.height.md + 'px'};
    }
    &[data-scrolling='true'] {
      top: ${theme.desktopHeader.height.scroll + 'px'};
    }
    .sub-menu-inner-wrapper {
    }
  }
`;

const StyledCloseButton = styled(CloseButton)`
  position: absolute;
  right: 1rem;
  top: 1.3rem;
  z-index: 2;
  svg {
    width: 10px;
    height: 10px;
  }
`;

const SubMenuContent = styled('div')`
  display: flex;
  width: 100%;
  position: relative;
  .sub-menu-categories {
    width: 65%;
  }
  .sub-menu-shop-by {
    width: 35%;
  }
  .borders {
    > div {
      position: absolute;
      top: 0;
      height: 100%;
      border-right: 1px solid ${theme.colors.border};
    }
  }
`;

const SubMenuCategories = styled('div')`
  position: relative;
  padding-bottom: 40px;
  //LEVEL2
  > ul {
    margin: 0 0;
    padding: 2rem 3rem;
    > li {
      list-style: none;
      //LEVEL3
      > ul {
        > li {
          > a {
          }
          //LEVEL4
          > ul {
            display: none;
            > li {
            }
          }
        }
      }
    }
  }
  li.open {
    > ul {
      display: block !important;
    }
  }
  li.show-all > a {
    //color: ${theme.colors.primary};
    //font-weight: ${theme.weights.bold};
    text-decoration: underline;
  }
  a:hover {
    text-decoration: underline;
  }

  //DIFFERENT TYPES
  &.vertical {
    > ul {
      flex-direction: column;
      > li {
        width: 100%;
        line-height: 2.4em;
        > a {
          font-size: ${theme.desktopHeader.nav.subMenu.lv3.fontSize};
        }
        //LEVEL3
        > ul {
          display: none;
        }
      }
    }
  }
  &.mega-menu {
    > ul {
      display: flex;
      flex-wrap: wrap;
      > li {
        width: 25%;
        margin: 0 0;
        > a {
          font-size: ${theme.desktopHeader.nav.subMenu.lv2.fontSize};
          font-weight: ${theme.weights.medium};
          margin-bottom: 0.5rem;
          display: block;
        }
        //LEVEL3
        > ul {
          margin-bottom: 2rem;
          > li {
            line-height: 1.6em;
            > a {
              font-size: ${theme.desktopHeader.nav.subMenu.lv3.fontSize};
            }
            //LEVEL 4
            > ul {
              display: none;
            }
          }
        }
      }
    }
  }
  &[data-brands='true'] {
    > ul {
      columns: 5;
      display: block;
      > li {
        margin: 5px;
        > a {
          text-transform: none;
        }
      }
    }
  }
`;

const SubMenuTitle = styled('h3')`
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: center;
  width: 100%;
  clear: both;
  padding: 1rem 0.5rem;
  background: ${theme.colors.primary};
  text-transform: none;
  a {
    text-decoration: underline !important;
    text-transform: none;
    color: ${theme.colors.white};
    &:hover {
      color: ${theme.colors.white} !important;
    }
  }
`;

const SubCategories = ({
  mainCategory,
  level,
  setActiveCategory,
  setSubMenu,
  toggleMenu,
  setToggleMenu,
}) => {
  let limiter = 200;
  if (level >= 3) limiter = 5;
  if (mainCategory.subcategories.length)
    return (
      <ul>
        {mainCategory.subcategories.map((category, index) => (
          <React.Fragment key={category.id}>
            {index <= limiter && (
              <li className={category.id === toggleMenu ? 'open' : ''}>
                <CategoryLink
                  onClick={() => {
                    setSubMenu(false);
                    setActiveCategory(category);
                  }}
                  category={category}
                >
                  {category.name}
                </CategoryLink>
                {category.subcategories &&
                  category.subcategories.length > 0 && (
                    <SubCategories
                      mainCategory={category}
                      level={level + 1}
                      setActiveCategory={setActiveCategory}
                      setSubMenu={setSubMenu}
                      toggleMenu={toggleMenu}
                      setToggleMenu={setToggleMenu}
                    />
                  )}
              </li>
            )}
          </React.Fragment>
        ))}
        {level === 3 && (
          <li className="show-all">
            <CategoryLink
              onClick={() => {
                setSubMenu(false);
                setActiveCategory(mainCategory);
              }}
              category={mainCategory}
            >
              {t('Show all')}
            </CategoryLink>
          </li>
        )}
      </ul>
    );
  else return null;
};

const SubMenu = ({ category, setActiveCategory, setSubMenu, scrolling }) => {
  const [toggleMenu, setToggleMenu] = useState(0);

  const subMenuRef = useRef();
  const isBrandCat = category.id === config.routes.brand.id;

  useEffect(() => {
    //CLOSE OUTSIDE LISTENER
    const listener = (event) => {
      if (subMenuRef.current && !subMenuRef.current.contains(event.target)) {
        setSubMenu(false);
      }
    };
    document.addEventListener('click', listener);
    return () => {
      document.removeEventListener('click', listener);
    };
  }, [subMenuRef, setSubMenu]);

  if (category && category.subcategories && category.subcategories.length) {
    return (
      <SubMenuWrapper
        data-scrolling={scrolling}
        className={cx('sub-menu-wrapper', 'mega-menu')}
      >
        <div ref={subMenuRef} className="sub-menu-inner-wrapper">
          <StyledCloseButton onClick={() => setSubMenu(false)} />
          <SubMenuContent>
            <SubMenuCategories
              className={cx('sub-menu-categories', 'mega-menu')}
              data-brands={isBrandCat}
            >
              <SubCategories
                mainCategory={category}
                level={2}
                setActiveCategory={setActiveCategory}
                setSubMenu={setSubMenu}
                toggleMenu={toggleMenu}
                setToggleMenu={setToggleMenu}
              />
              <SubMenuTitle>
                <CategoryLink
                  category={category}
                  onClick={() => {
                    setActiveCategory(category);
                    setSubMenu(false);
                  }}
                >
                  {t('See the entire range')}
                </CategoryLink>
              </SubMenuTitle>
            </SubMenuCategories>
            <div
              className="sub-menu-shop-by"
              onClick={() => {
                setSubMenu(false);
              }}
            >
              <ShopByArea />
              <CampaignImage />
            </div>
          </SubMenuContent>
        </div>
      </SubMenuWrapper>
    );
  } else return null;
};

export default SubMenu;
