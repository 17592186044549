import React from 'react';
import Image from '@jetshop/ui/Image';

const Logo = ({ size = [400], secondary = false }) => {
  const src = secondary
    ? `/pub_images/original/logo-white.png`
    : `/pub_images/original/logo.png`;
  return <Image src={src} sizes={size} aspect={'300:100'} crop={false} />;
};

export default Logo;
