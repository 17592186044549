import React, { useContext } from 'react';
import { styled } from 'linaria/react';
import MaxWidth from '../MaxWidth';
import Image from '@jetshop/ui/Image';
import NewsletterBox from './Newsletter/NewsletterBox';
import { theme } from '../../Theming/Theming';
import SiteContentContext from '../../SiteContent/SiteContentContext';

const FooterWrapper = styled('footer')`
  background: ${theme.footer.background};
  z-index: 2;
  a,
  a:active {
    text-decoration: none;
    color: ${theme.footer.color};
  }
  .footer-upper-wrapper {
    padding: 50px 0 0;
    a {
    }
  }
  .footer-content {
    border-top: 2px solid white;
    padding: 3rem 0 0;
    color: ${theme.footer.color};
  }
`;

export const FooterContentWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 0 1rem;
  padding-top: 0.5rem;
  margin-bottom: 3rem;
  ${theme.below.sm} {
    flex-direction: column;
  }
  > div {
    width: 100%;
    padding: 2rem 0;
    ${theme.only.md} {
      width: 40%;
      text-align: center;
    }
    ${theme.above.lg} {
      width: 20%;
      padding: 3rem 1rem;
      &.footer-logo {
        width: 30%;
      }
    }
    > h3 {
      margin-bottom: 1em !important;
    }
    > ul {
      > li {
        line-height: 2em;
      }
    }
    p {
      line-height: 1.7em;
      margin-bottom: 1rem;
    }
  }
`;

const Footer = () => {
  const { footer1, footer2, footer3, footerLogo } =
    useContext(SiteContentContext);
  return (
    <>
      <FooterWrapper>
        <div className="newsletter-box">
          <NewsletterBox />
        </div>
        <div className="footer-content">
          <MaxWidth>
            <FooterContentWrapper>
              {footerLogo && (
                <div className="footer-logo">
                  <Image aspect="5:1" src={footerLogo} />
                </div>
              )}
              <div>{footer1}</div>
              <div>{footer2}</div>
              <div>{footer3}</div>
            </FooterContentWrapper>
          </MaxWidth>
        </div>
      </FooterWrapper>
    </>
  );
};

export default Footer;
