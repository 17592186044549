import bodyReg from './DINOT-Regular.woff2';
import bodyMedium from './DINOT-Medium.woff2';
import bodyLight from './DINOT-Light.woff2';
import bodyBold from './DINOT-Bold.woff2';
import headerReg from './NickyLaatz.woff2';
import { css } from 'linaria';

export default function loadCss() {
  return css`
    :global(html) {
      @font-face {
        font-family: 'dinot';
        src: url(${bodyReg}) format('woff2');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
      }

      @font-face {
        font-family: 'dinot';
        src: url(${bodyLight}) format('woff2');
        font-weight: 300;
        font-style: normal;
        font-display: swap;
      }

      @font-face {
        font-family: 'dinot';
        src: url(${bodyMedium}) format('woff2');
        font-weight: 500;
        font-style: normal;
        font-display: swap;
      }

      @font-face {
        font-family: 'dinot';
        src: url(${bodyBold}) format('woff2');
        font-weight: 700;
        font-style: normal;
        font-display: swap;
      }

      @font-face {
        font-family: 'nicky';
        src: url(${headerReg}) format('woff2');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
      }
    }
  `;
}
