import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theming/Theming';
import { ProductInfoMarginBottom } from '../StyledComponents';

const StyledButton = styled('button')`
  background: transparent;
  border: 1px solid #777;
  border-radius: 2px;
  cursor: pointer;
  text-align: center;
  padding: 0.2rem 0.8rem;
  font-size: 0.9rem;
  width: 100%;
  position: relative;
  &[data-selected='true'] {
    &:after {
      content: '';
      width: 100%;
      height: 4px;
      background: ${theme.colors.font};
      position: absolute;
      left: 0;
      bottom: -10px;
    }
  }
  &[data-disabled='true'] {
    cursor: not-allowed;
  }
  &.not-buyable,
  &[data-disabled='true'] {
    border-color: #eee;
    background: ${theme.colors.greys[0]};
    color: #aaa;
    text-decoration: line-through;
    &[data-selected='true'] {
      border-color: #777;
    }
  }
  &.color {
    padding: 0;
    .color-circle {
      > div {
        width: 36px;
        height: 36px;
        border-radius: 0;
        border: none;
      }
    }
  }
`;
const VariantButtonWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0 -0.5% 0;
  ${ProductInfoMarginBottom}
  &.invalid button {
    box-shadow: 0 0 10px rgba(195, 45, 45, 0.38);
  }
  .button-wrapper {
    padding: 0 0.5% 1%;
  }
  &.package {
    .button-wrapper {
      button {
        padding: 5px 10px;
      }
    }
  }
  &.standard {
    .button-wrapper {
      width: 25%;
      min-width: 50px;
      ${theme.above.lg} {
        width: 20%;
      }
      ${theme.above.lg} {
        width: 16.666667%;
      }
    }
  }
`;

export const VariantHeading = styled('h4')`
  margin-bottom: 0.3rem;
`;

const VariantWrapper = styled('div')`
  width: 100%;
`;
const VariantButton = ({
  option,
  variantHandler,
  showValidationMessage,
  packageProduct,
}) => {
  const {
    getSelectedValue,
    validateSelection,
    selectValue,
    getVariantForSelection,
  } = variantHandler;
  const selectedValue = getSelectedValue(option);

  return (
    <VariantWrapper>
      <VariantHeading>{option.name}</VariantHeading>
      <VariantButtonWrapper
        className={`${showValidationMessage && 'invalid'} ${
          packageProduct ? 'package' : 'standard'
        }`}
      >
        {option.values.map((value, index) => {
          const validation = validateSelection(value, option);
          const item = getVariantForSelection(value, option);
          return (
            <div className="button-wrapper" key={index}>
              <StyledButton
                onClick={() => selectValue(value, option)}
                data-disabled={validation === 'invalid'}
                data-secondary={value !== selectedValue}
                data-selected={value === selectedValue}
                className={`${
                  item && item.stockStatus && !item.stockStatus.buyable
                    ? 'not-buyable '
                    : ''
                }`}
              >
                <span>{value}</span>
              </StyledButton>
            </div>
          );
        })}
      </VariantButtonWrapper>
    </VariantWrapper>
  );
};

export default VariantButton;
