export const config = {
  routes: {
    favourites: {
      path: process.env.REACT_APP_FAV_PATH || '/favoriter',
    },
    blog: {
      path: process.env.REACT_APP_BLOG_PATH || '/blogg',
      id: process.env.REACT_APP_BLOG_ID || 218,
      mainLevel: 1,
      menu: true,
    },
    brand: {
      id: 154,
    },
    shopByArea: {
      id: 198,
    },
    category: {
      hide: false,
    },
    product: {
      hide: false,
    },
  },
  settings: {
    storeName: 'Mr Plant',
    siteContentId: 51,
    klarna:
      'klarna_live_client_LXJnJUlBNFFFcE9RY0NwMyEvV3BvS0EpZ1V3UlkxdUssMTU3ZWMwMDItZDIwMy00OWNmLTk1MGItMzlmNmYwYWE4YWRjLDEsa3BudHdRa0duQTFJSTIrL3B5QUg0VzBkVTlySjZWRU1nMm1wRmhPTzJoUT0',
  },
};
