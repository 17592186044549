import React, { useState } from 'react';
import { styled } from 'linaria/react';
import RightIcon from './icons/RightIcon';
import { theme } from '../Theming/Theming';

const ButtonWrapper = styled('div')`
  position: fixed;
  right: 0;
  bottom: 5%;
  z-index: 100;
  button {
    background: ${theme.colors.primary};
    color: white;
    width: 36px;
    height: 36px;
    ${theme.above.md} {
      width: 46px;
      height: 46px;
    }
    transform: rotate(-90deg);
  }
`;

const ScrollArrow = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 200) {
      setVisible(true);
    } else if (scrolled <= 200) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    if (typeof window !== 'undefined') {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
        /* you can also use 'auto' behaviour 
           in place of 'smooth' */
      });
    }
  };

  if (typeof window !== 'undefined') {
    window.addEventListener('scroll', toggleVisible);
  }

  return (
    <ButtonWrapper style={{ display: visible ? 'block' : 'none' }}>
      <button onClick={scrollToTop}>
        <RightIcon />
      </button>
    </ButtonWrapper>
  );
};

export default ScrollArrow;
